import { default as changeCLPag75YwpMeta } from "/tmp/build_4e6bfb51/pages/account/change.vue?macro=true";
import { default as indexyOSZwKVP56Meta } from "/tmp/build_4e6bfb51/pages/account/index.vue?macro=true";
import { default as loginXg4L5RQ4iAMeta } from "/tmp/build_4e6bfb51/pages/account/login.vue?macro=true";
import { default as _91token_93Fcoc1zFqGPMeta } from "/tmp/build_4e6bfb51/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeBVQdXrSWhDMeta } from "/tmp/build_4e6bfb51/pages/account/password-reset/complete.vue?macro=true";
import { default as donetslk3embxPMeta } from "/tmp/build_4e6bfb51/pages/account/password-reset/done.vue?macro=true";
import { default as indexuZZSQMKePfMeta } from "/tmp/build_4e6bfb51/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetujVDahMUXdMeta } from "/tmp/build_4e6bfb51/pages/account/password-reset.vue?macro=true";
import { default as passwordYfiFZn4zy0Meta } from "/tmp/build_4e6bfb51/pages/account/password.vue?macro=true";
import { default as registerqOevizGerVMeta } from "/tmp/build_4e6bfb51/pages/account/register.vue?macro=true";
import { default as cookie_45policyc3pApS44DpMeta } from "/tmp/build_4e6bfb51/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policye6icftWaoRMeta } from "/tmp/build_4e6bfb51/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45use3udDAczfgnMeta } from "/tmp/build_4e6bfb51/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_932vh6134AQ4Meta } from "/tmp/build_4e6bfb51/pages/events/[slug].vue?macro=true";
import { default as indexc7miz0vkuQMeta } from "/tmp/build_4e6bfb51/pages/events/index.vue?macro=true";
import { default as indexhR11kyfHXLMeta } from "/tmp/build_4e6bfb51/pages/index.vue?macro=true";
import { default as _91id_93GJnjj4uU1gMeta } from "/tmp/build_4e6bfb51/pages/orders/[id].vue?macro=true";
import { default as indexRoVDfMetMhMeta } from "/tmp/build_4e6bfb51/pages/orders/index.vue?macro=true";
import { default as indexdQO7t8hyMkMeta } from "/tmp/build_4e6bfb51/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: changeCLPag75YwpMeta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/change.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexyOSZwKVP56Meta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginXg4L5RQ4iAMeta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: password_45resetujVDahMUXdMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetujVDahMUXdMeta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/password-reset.vue").then(m => m.default || m),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_4e6bfb51/pages/account/password-reset/[uidb64]/[token].vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_4e6bfb51/pages/account/password-reset/complete.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_4e6bfb51/pages/account/password-reset/done.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_4e6bfb51/pages/account/password-reset/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordYfiFZn4zy0Meta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerqOevizGerVMeta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/account/register.vue").then(m => m.default || m)
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_4e6bfb51/pages/docs/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_4e6bfb51/pages/docs/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_4e6bfb51/pages/docs/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_4e6bfb51/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_4e6bfb51/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_4e6bfb51/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/tmp/build_4e6bfb51/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexRoVDfMetMhMeta || {},
    component: () => import("/tmp/build_4e6bfb51/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_4e6bfb51/pages/purchase/index.vue").then(m => m.default || m)
  }
]